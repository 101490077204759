import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase, {
  LogoLink as LogoLinkBase,
  NavLinks,
  NavLink as NavLinkBase,
  PrimaryLink as PrimaryLinkBase
} from "../headers/light.js";
import { Container as ContainerBase, ContentWithVerticalPadding, Content2Xl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import logoImageSrc from "images/neko-nik-store.svg";
import serverIllustrationImageSrc from "images/server-illustration-2.svg";
import DesignIllustration from "../../images/design-illustration.svg";

const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-gray-900 text-gray-100 pt-8 pl-16 pr-16`;
const Header = tw(HeaderBase)`max-w-none -mt-8 py-8 -mx-8 px-8`;
const NavLink = tw(NavLinkBase)`lg:text-gray-100 lg:hocus:text-gray-300 lg:hocus:border-gray-100`;
const LogoLink = tw(LogoLinkBase)`text-gray-100 hocus:text-gray-300 w-20 h-20`;
const PrimaryLink = tw(PrimaryLinkBase)`shadow-raised lg:bg-primary-400 lg:hocus:bg-primary-500`;

const Container = tw(ContainerBase)``;
const Row = tw.div`flex items-center flex-col lg:flex-row`;
const Column = tw.div`lg:w-1/2`;
const TextColumn = tw.div`text-center lg:text-left`;
const IllustrationColumn = tw(Column)`mt-16 lg:mt-0 lg:ml-16`;
const Heading = tw(SectionHeading)`max-w-3xl lg:max-w-4xl lg:text-left leading-tight`;
const Description = tw(SectionDescription)`mt-4 max-w-2xl text-gray-100 lg:text-base mx-auto lg:mx-0`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 text-sm sm:text-base px-6 py-5 sm:px-10 sm:py-5 bg-primary-400 inline-block hocus:bg-primary-500`;
const Image = tw.img`w-144 ml-auto`

export default ({
  heading = "IT Solutions for All Your Needs",
  description = "Innovative solutions to boost your business. Choose from a variety of services that suit your needs. We provide the best solutions for your business.",
  primaryButtonText = "Schedule a Virtual Meet",
  primaryButtonUrl = "https://calendly.com/neko-nik/general-meet",
  imageSrc = serverIllustrationImageSrc,
//   imageSrc = DesignIllustration,
}) => {
  const logoLink = (
    <a href="/">
        <img src={logoImageSrc} alt="Logo" style={{ width: "160px" }} />
    </a>
  );
  const navLinks = [
    <NavLinks key={1}>
        <NavLink href="/">Home</NavLink>
        <NavLink href="https://forums.nekonik.com" target="_blank" rel="noopener noreferrer">Forums</NavLink>
        <NavLink href="https://www.nekonik.com/about" target="_blank" rel="noopener noreferrer">About</NavLink>
        <NavLink href="https://www.nekonik.com/contact" target="_blank" rel="noopener noreferrer">Contact</NavLink>
        <PrimaryLink href="https://www.nekonik.com" target="_blank" rel="noopener noreferrer">My Blog</PrimaryLink>
    </NavLinks>
  ];
  return (
    <PrimaryBackgroundContainer>
      <Content2Xl>
        <Header logoLink={logoLink} links={navLinks} />
        <Container>
          <ContentWithVerticalPadding>
            <Row>
              <TextColumn>
                <Heading>{heading}</Heading>
                <Description>{description}</Description>
                <PrimaryButton as="a" href={primaryButtonUrl} target="_blank" rel="noopener noreferrer">{primaryButtonText}</PrimaryButton>
              </TextColumn>
              <IllustrationColumn>
                <Image src={imageSrc} />
              </IllustrationColumn>
            </Row>
          </ContentWithVerticalPadding>
        </Container>
      </Content2Xl>
    </PrimaryBackgroundContainer>
  );
};
