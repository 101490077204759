import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "images/neko-nik-store.svg";
import GithubIcon from "images/github-mark.png";
import { ReactComponent as DiscordIcon } from "images/discord-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";

const Container = tw.div`relative bg-gray-900 text-gray-100 -mx-8 -mb-8 px-8 pb-8`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8`
const FiveColumns = tw.div`flex flex-wrap justify-between`;

const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/5`;

const ColumnHeading = tw.h5`font-bold uppercase`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const CompanyAddress = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`
const CopyrightNotice = tw.div``
const CompanyInfo = tw.div``

const Divider = tw.div`my-8 border-b-2 border-gray-800`
export default () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <CompanyColumn>
            <LogoContainer>
                <a href="/"><img src={LogoImage} alt="Neko Nik Store Logo" style={{ width: "220px", justifyContent: "center" }} /></a>
            </LogoContainer>
            <CompanyAddress>
                Shyanuboganahalli <br />
                Bengaluru, Karnataka <br />
                India 560083
            </CompanyAddress>
            <SocialLinksContainer>
              <SocialLink href="https://forums.nekonik.com">
                <DiscordIcon />
              </SocialLink>
              <SocialLink href="https://twitter.com/Neko7Nik">
                <TwitterIcon />
              </SocialLink>
              <SocialLink href="https://github.com/Neko-Nik">
                <img src={GithubIcon} alt="Github" style={{ width: "16px" }} />
              </SocialLink>
              <SocialLink href="https://www.linkedin.com/company/neko-nik">
                <LinkedinIcon />
              </SocialLink>
            </SocialLinksContainer>
          </CompanyColumn>
          <Column>
            <ColumnHeading>Quick Links</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="https://www.nekonik.com">Blog</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://www.nekonik.com/faqs">FAQs</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://www.nekonik.com/contact">Support</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://www.nekonik.com/about">About Us</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Product</ColumnHeading>
            <LinkList>
              {/* <LinkListItem> */}
                {/* Stripe Payments API */}
                {/* <Link href="https://www.nekonik.com">Log In</Link> */}
              {/* </LinkListItem> */}
              <LinkListItem>
                <Link href="https://status.nekonik.com/status/neko-nik" taget="_blank" rel="noopener noreferrer">System Status</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://jsonviewer.nekonik.com" taget="_blank" rel="noopener noreferrer">JSON Viewer</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://draw.nekonik.com" taget="_blank" rel="noopener noreferrer">Flowchart Maker</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://apt.nekonik.com" taget="_blank" rel="noopener noreferrer">Debian APT Repository</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://auth-n.nekonik.com" taget="_blank" rel="noopener noreferrer">Auth-N (Deprecated)</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="https://www.nekonik.com/disclaimer">Disclaimer</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://www.nekonik.com/privacy">Privacy Policy</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://www.nekonik.com/terms">Terms of Service</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://www.nekonik.com/impressum">Imprint (Impressum)</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="mailto:nikhil@nekonik.com">nikhil@nekonik.com</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="https://www.nekonik.com/contact">Report Abuse</Link>
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider/>
        <CopyrightAndCompanyInfoRow>
          <CopyrightNotice>&copy; Copyright 2024, Neko Nik.</CopyrightNotice>
          <CompanyInfo>Persuing Excellence in Technology</CompanyInfo>
        </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  );
};
